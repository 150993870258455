<template>
  <section class="grid-view wishlist-items">
    <b-card
      v-for="item in wishlist"
      :key="item.key"
      class="ecommerce-card"
      no-body
    >
      <div class="item-img text-center d-flex justify-content-center">
        <b-link :to="{ name: 'marketplace-details', params: { slug: item.product_id } }">
          <b-img
            :alt="`${item.product.product_name}`"
            fluid
            class="card-img-top"
            :src="item.product.image"
          />
        </b-link>
      </div>

      <!-- Product Details -->
      <b-card-body>
        <div class="item-wrapper">
          <div class="item-rating">
            <ul class="unstyled-list list-inline">
              <li
                v-for="star in 5"
                :key="star"
                class="ratings-list-item"
                :class="{'ml-25': star-1}"
              >
                <feather-icon
                  icon="StarIcon"
                  size="16"
                  :class="[{'fill-current': star <= item.product.rating}, star <= item.product.rating ? 'text-warning' : 'text-muted']"
                />
              </li>
            </ul>
          </div>
          <div>
            <h6 class="item-price">
              ${{ item.product.price }}
            </h6>
          </div>
        </div>
        <h6 class="item-name">
          <b-link
            class="text-body"
            :to="{ name: 'apps-e-commerce-product-details', params: { slug: item.product_id } }"
          >
            {{ item.product.product_name }}
          </b-link>
          <b-card-text class="item-company">
            By <b-link class="ml-25">
              {{ item.product.manufactured }}
            </b-link>
          </b-card-text>
        </h6>
        <b-card-text class="item-description">
          {{ item.product.descr }}
        </b-card-text>
      </b-card-body>

      <!-- Action Buttons -->
      <div class="item-options text-center">
        <b-button
          variant="light"
          class="btn-wishlist remove-wishlist"
          @click="removeWishlist(item._id)"
        >
          <feather-icon icon="XIcon" />
          <span>Remove</span>
        </b-button>
        <b-button
          variant="primary"
          class="btn-cart move-cart"
          @click="
            item.isInCart
              ? $router.push({name: 'marketplace-checkout'})
              : addToCart(item.product_id)
          "
        >
          <feather-icon
            icon="ShoppingCartIcon"
            class="mr-50"
          />
          <span>{{ item.isInCart ? 'View In Cart' : 'Move to Cart' }}</span>
        </b-button>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  BCard, BCardBody, BImg, BCardText, BLink, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard, BCardBody, BImg, BCardText, BLink, BButton,
  },
  data() {
    return {
      wishlist: [],
    }
  },
  created() {
    this.get_wishlist()
  },
  methods: {
    get_wishlist() {
      this.$http
        .get(`/api/wishlist`)
        .then(response => {
          const { success, output } = response.data

          if (success) {
            this.wishlist = output
          } else {
            this.wishlist = []
          }
        })
        .catch(error => {
          // console.log('get product error')
        })
    },

    removeWishlist(id) {
      this.$http
        .delete(`/api/wishlist/${id}`)
        .then(response => {
          const { success, message } = response.data

          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Product Notification',
                  icon: 'CheckCircleIcon',
                  text: message,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )

            this.get_wishlist()
            // this.$router.push('/wishlist')
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Product Notification',
                  icon: 'AlertCircleIcon',
                  text: 'Unable to remove from wishlist, please try again later',
                  variant: 'error',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }

          this.isLoading = false
        })
        .catch(error => {
          // alert('ERROR')
        })
    },

    addToCart(product_id) {
      const form_data = {}
      form_data.quantity = 1

      this.$http
        .post(`/api/add-to-cart/${product_id}`, {
          form_data,
        })
        .then(response => {
          const { success, message } = response.data

          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Operation Success',
                  icon: 'CheckCircleIcon',
                  text: message,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Operation Failed',
                  icon: 'AlertCircleIcon',
                  text: message,
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }

          this.get_wishlist()
        })
        .catch(() => {
          // this.isLoading = false
        })
    },
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
